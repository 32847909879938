import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import BodyView from "@/layout/BodyView.vue"
import AuthView from "@/components/common/block/auth/AuthView.vue"
import LoginPage from "@/components/common/block/auth/LoginPage.vue"
import IndexDefault from "@/pages/dashboards/IndexDefault.vue"
import IndexEmails from "@/pages/emails/IndexEmails.vue"
import IndexTodo from "@/pages/todo/IndexTodo.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/dashboards/dashboard_default"
  },
  {
    path: '/',
    name: 'home',
    component: BodyView,
    meta: {
      title: 'Admiro - Premium Vue Admin Template',
    },
    children: [
      {
        path: '',
        name: 'defaultRoot',
        component: IndexDefault,
        meta: {
          title: 'Admiro - Premium Vue Admin Template',
        }
      }
    ]
  },
  {
    path: "/auth",
    component: AuthView,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginPage,
        meta: {
          title: 'Admiro - Premium Vue Admin Template',
        }
      }
    ]
  },
  {
    path: "/dashboards",
    component: BodyView,
    children: [
      {
        path: "dashboard_default",
        name: "default",
        component: IndexDefault,
        meta: {
          title: 'Dashboards | Admiro - Premium Vue Admin Template',
        }
      },
    ]
  },
  {
    path: "/app",
    component: BodyView,
    children: [
      {
        path: "emails",
        name: "emails",
        component: IndexEmails,
        meta: {
          title: 'Emails',
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (typeof (to.meta.title) === 'string') {
    document.title = to.meta.title;
  }
  const path = ['/auth/login', '/auth/register'];
  if (path.includes(to.path) || localStorage.getItem('easyREGCRMToken')) {
    return next();
  }
  next('/auth/login');
});

export default router
