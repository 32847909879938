import { defineStore } from "pinia";
import { ref, } from "vue";
import { history, invoice, seller, swipers, order, details, tables, swipersitems } from "@/core/data/dashboards"


interface dashboard_info {
    dashboard_emails_lastday: string,
    dashboard_emails_lastfivemin: string,
    dashboard_emails_lasthour: string,
    dashboard_emails_queue: string,
    dashboard_payments_amount_lastday: string,
    dashboard_payments_amount_lasthour: string,
    dashboard_payments_number_lastday: string,
    dashboard_payments_number_lasthour: string,
    dashboard_refunds_amount_lastday: string,
    dashboard_refunds_amount_lasthour: string,
    dashboard_refunds_number_lastday: string,
    dashboard_refunds_number_lasthour: string,
    dashboard_registrations_lastday: string,
    dashboard_registrations_lastfivemin: string,
    dashboard_registrations_lasthour: string,
    dashboard_sms_lastday: string,
    dashboard_sms_lastfivemin: string,
    dashboard_sms_lasthour: string,
    dashboard_sms_queue: string
}
export const useDashboardStore = defineStore("dashboard", () => {
    let data = ref(history)
    let invoices = ref(invoice)
    let sellers = ref(seller)
    let swiperss = ref(swipers)
    let orders = ref(order)
    let detail = ref(details)
    let table = ref(tables)
    let swipersitem = ref(swipersitems)

    let dashboard = ref<dashboard_info>();
    function setDashboard(info: any) {
        dashboard.value = { ...info };
    }
    return {
        dashboard,
        setDashboard,
        data,
        invoices,
        sellers,
        swiperss,
        orders,
        detail,
        table,
        swipersitem
    }
})