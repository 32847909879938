import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loader-wrapper"
}

import { onMounted, ref, watch, onUnmounted } from "vue"
import { useRouter } from 'vue-router'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

let showLoader = ref<boolean>(false)
let router = useRouter()
watch(
  () => router,
  () => {
    showLoader.value = true;
    setTimeout(() => {
      showLoader.value = false
    }, 1000);
  },
  { deep: true },
);
function add() {

}
onMounted(() => {
  let allBgImageCover = document.getElementsByClassName('bg-img-cover');
  window.addEventListener('beforeunload', add)
  setTimeout(() => {
    for (let i = 0; i < allBgImageCover.length; i++) {
      var image = allBgImageCover[i]
      var parentEl: any = allBgImageCover[i].parentElement
      var src = image.getAttribute('src')
      parentEl.style.backgroundImage = "url(" + src + ")"
      parentEl.style.backgroundSize = "cover"
      parentEl.style.backgroundPosition = "center"
      parentEl.classList.add('bg-size')
      image.classList.add('d-none')
    }
  }, 0);

})
onUnmounted(() => {
  window.removeEventListener('beforeunload', add)

})

return (_ctx: any,_cache: any) => {
  const _component_ModalTarget = _resolveComponent("ModalTarget")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showLoader))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "loader" }, [
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span"),
            _createElementVNode("span")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalTarget),
    _createVNode(_component_router_view)
  ], 64))
}
}

})