import ApiInstance from "./ApiBase";

export const GetEmailList = async (page: number, category: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-list/${page}/${category}`);
    return res?.data;
};

export const GetEmailCategoryBadge = async (): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-category-badge`);
    return res?.data;
};

export const GetEmailSync = async (emails_id: string): Promise<any> => {
    const res = await ApiInstance.get(`/get-email-sync/${emails_id}`);
    return res?.data;
};

export const SetEmailCategory = async (
    emails_id: string,
    category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("emails_id", emails_id);
    data.append("category", category);
    const res = await ApiInstance.post(`set-email-category`, data);
    return res?.data;
};


export const SaveRule = async (
    rule_type: string,
    rule_text: string,
    rule_category: string,
): Promise<any> => {
    const data = new FormData();
    data.append("rule_type", rule_type);
    data.append("rule_text", rule_text);
    data.append("rule_category", rule_category);
    const res = await ApiInstance.post(`save-rule`, data);
    return res?.data;
};


export const GetSystemDashboard = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getSystemDashboard`);
    return res?.data;
};
