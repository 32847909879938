interface MenuItem {
    headTitle1?: string;
    headTitle2?: string;
    title?: string;
    icon?: string;
    icon1?: string;
    type: string;
    badgeType?: string;
    active?: boolean;
    isPinned?: boolean;
    path?: string;
    bagde?: string,
    children?: MenuItem[];
    bookmark?: boolean;
}

export const menu: MenuItem[] = [
    {
        title: "Dashboards",
        path: "/dashboards/dashboard_default",
        icon: "Home-dashboard",
        icon1: "fill-home",
        type: "link",
        badgeType: "badge",
        active: false,
        isPinned: false,
    },
    {
        path: "/app/emails",
        icon: "Message",
        icon1: "fill-email",
        title: "Emails",
        isPinned: false,
        active: false,
        type: "link"
    },
]