<template>
  <Breadcrumbs
    main="Dashboard"
    defaultdesc="true"
    mains="Dashboard"
    title="Default"
  />
  <div class="container-fluid dashboard-3">
    <div class="row">
      <Registration @on-reload="handleOnReload" />
      <Email @on-reload="handleOnReload" />
      <SMS @on-reload="handleOnReload" />
      <Elavon @on-reload="handleOnReload" />
    </div>
  </div>
</template>
<script lang=ts setup>
import { GetSystemDashboard } from "@/services/ApiCore";
import { defineAsyncComponent, onMounted } from "vue";
const Registration = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Registration.vue")
);
const Email = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Email.vue")
);
const SMS = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/SMS.vue")
);
const Elavon = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Elavon.vue")
);
import { useDashboardStore } from "@/store/dashboard";
const store = useDashboardStore();
import { useCommonStore } from "@/store/common";
const commonStore = useCommonStore();
onMounted(() => {
  (async () => {
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
});
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
}
</script>