import { defineStore } from 'pinia'
import { ref } from 'vue'
import { computed } from 'vue'
import { sent, starred, draft, trash, work, support } from "@/core/data/emails"

export const useCommonStore = defineStore('Common', () => {
    let isLoading = ref<boolean>(false);
    let sents = ref(sent)
    let starr = ref(starred)
    let items = ref(draft)
    let deletes = ref(trash)
    let works = ref(work)
    let supported = ref(support)


    const toggleLoader = () => {
        isLoading.value = true;
    };
    return {
        isLoading,
        sents,
        starr,
        items,
        deletes,
        works,
        supported,
        toggleLoader
    }
})
