import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid dashboard-3" }
const _hoisted_2 = { class: "row" }

import { GetSystemDashboard } from "@/services/ApiCore";
import { defineAsyncComponent, onMounted } from "vue";
import { useDashboardStore } from "@/store/dashboard";
import { useCommonStore } from "@/store/common";

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexDefault',
  setup(__props) {

const Registration = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Registration.vue")
);
const Email = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Email.vue")
);
const SMS = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/SMS.vue")
);
const Elavon = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Elavon.vue")
);
const store = useDashboardStore();
const commonStore = useCommonStore();
onMounted(() => {
  (async () => {
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
});
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Dashboard",
      defaultdesc: "true",
      mains: "Dashboard",
      title: "Default"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Registration), { onOnReload: handleOnReload }),
        _createVNode(_unref(Email), { onOnReload: handleOnReload }),
        _createVNode(_unref(SMS), { onOnReload: handleOnReload }),
        _createVNode(_unref(Elavon), { onOnReload: handleOnReload })
      ])
    ])
  ], 64))
}
}

})